import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InViewport from '@components/InViewport';
import EventCard from '@components/EventCard';
import cn from 'classnames';
import styles from './styles';

const EventList = ({ years, events }) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => { 
    setTimeout(() => {
      setShowContent(true);
    }, 2500)
  },[showContent])

  return (
    <div className="bg-black py-16">
      <div className="wrapper">
        <div className={cn(styles.wrapper, {
          [styles.active]: showContent
        })}>
          <div className="row">
            <div className="col-12 md:col-6 md:offset-3">
              {years && years.map((year) => (
              <InViewport key={year} className={styles.section}>
                <h2 className={styles.year}>{year}</h2>
                <div>
                  {events && events.map(event => {
                    if (event.node.year === year) {
                      return (
                        <EventCard key={event.node.id} event={event} />
                      )
                    }
                    return false;
                  })}
                </div>
              </InViewport>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EventList.propTypes = {
  years: PropTypes.array,
  events: PropTypes.array,
}

EventList.defaultProps = {
  years: [],
  events: []
}

export default EventList