import React from 'react'
import { graphql } from 'gatsby';
import sortBy from 'lodash.sortby';
import uniq from 'lodash.uniq';
import PropTypes from 'prop-types';
import localize from '@components/localize';
import Layout from '@components/Layout';
import EventList from '@components/EventList';
import PageHero from '@components/PageHero';
import SEO from '@components/seo';

export const query = graphql`
  query {
    allSanityBioPage {
      edges {
        node {
          hero {
            heroImageColor {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            heroImageColorMobile {
              asset {
                fluid(maxWidth: 768) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            heroImageMono {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            heroImageMonoMobile {
              asset {
                fluid(maxWidth: 768) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
          name {
            _type
            en
            jp
          }
          credit {
            _type
            en
            jp
          }
        }
      }
    }
    allSanityEvent {
      edges {
        node {
          id
          date
          description {
            _type
            en
            jp
          }
          isHiddenMonth
        }
      }
    }
  }
`;

const BioPage = ({
  data: { allSanityBioPage, allSanityEvent },
  pageContext: { title, description, metaImage }
}) => {
  let transformedEvents = [];
  let years = []

  allSanityEvent.edges.forEach(event => {
    const date = new Date(event.node.date);
    const year = date.getFullYear();
    event.node.year = year;
    event.node.month = date.getMonth() + 1;
    event.timestamp = date.getTime();
    transformedEvents.push(event);
    years.push(year);
  });

  years = uniq(years).sort();
  transformedEvents = sortBy(transformedEvents, ['timestamp']);

  return (
    <Layout>
      <SEO title={title} description={description} image={metaImage?.asset.url} />
      <PageHero 
        heroImages={allSanityBioPage.edges[0].node.hero} 
        headline={allSanityBioPage.edges[0].node.name} 
        description={allSanityBioPage.edges[0].node.credit}
      />
      <EventList years={years} events={transformedEvents} />
      <hr className="border-grey-dark" />
    </Layout>
  )
}

BioPage.propTypes = {
  data: PropTypes.shape({
    allSanityBioPage: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allSanityEvent: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metaImage: PropTypes.object,
  }),
};

BioPage.defaultProps = {
  data: {
    allSanityBioPage: {},
    allSanityEvent: {},
  },
  pageContext: {
    metaImage: {},
  },
};

export default localize(BioPage)
