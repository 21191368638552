import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Transition } from 'react-transition-group'
import { theme } from '@root/tailwind.config.js'
import InViewport from '@components/InViewport'
import cn from 'classnames';
import styles from './styles';

const PageHero = ({ heroImages, headline, description }) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => { setShowContent(true) }, [showContent])

  return (
    <>
      <InViewport 
        className={styles.wrapper}
        activeClass={styles.active}>
        <div className={styles.hero}>
          <Img
            fluid={[
              heroImages.heroImageMonoMobile.asset.fluid,
              {
                ...heroImages.heroImageMono.asset.fluid,
                media: `(min-width: ${theme.screens.md})`
              }
            ]}
            className={styles.mono}
            objectFit="cover"
            loading="eager"
            placeholderClassName="gatsby-image-placeholder"
            alt="Hero Image Mono"
          />
          <Img
            fluid={[
              heroImages.heroImageColorMobile.asset.fluid,
              {
                ...heroImages.heroImageColor.asset.fluid,
                media: `(min-width: ${theme.screens.md})`
              }
            ]}
            className={styles.color}
            objectFit="cover"
            style={{ position: "absolute" }}
            loading="eager"
            placeholderClassName="gatsby-image-placeholder"
            alt="Hero Image"
          />
        </div>
        {headline && (
        <Transition
          timeout={8000}
          in={showContent}
        >
          {state => (
            <>
              <div className={cn(styles.content)}>
                <div className="wrapper">
                  <h1 className={styles.headline}>{headline}</h1>
                  {description && (
                    <p className={styles.description}>{description}</p>
                  )}
                </div>
              </div>
            </>
          )}
        </Transition>
      )}
      </InViewport>
    </>
  )
}

PageHero.propTypes = {
  heroImages: PropTypes.object,
  headline: PropTypes.string, 
  description: PropTypes.string
}

PageHero.defaultProps = {
  heroImages: {},
  headline: '', 
  description: ''
}

export default PageHero