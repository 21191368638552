import React, { useContext } from 'react'
import { getShortMonth } from '@helpers/utils'
import PropTypes from 'prop-types'
import InViewport from '@components/InViewport'
import LocaleContext from '@context/LocaleContext'
import styles from './styles'

const EventCard = ({ event }) => {
  const { code } = useContext(LocaleContext);

  return (
    <InViewport className={styles.card}>
      {!event.node.isHiddenMonth && (
        <p className="mb-2 type-360 opacity-50">{code === 'jp' ? `${event.node.month}月` : getShortMonth(event.node.month)}</p>
      )}
      <p className="type-230 leading-relaxed">
        {event.node.description}
      </p>
    </InViewport>
  )
}

EventCard.propTypes = {
  event: PropTypes.shape({
    node: PropTypes.shape({
      isHiddenMonth: PropTypes.bool,
      month: PropTypes.number,
      description: PropTypes.string 
    })
  })
}

EventCard.defaultProps = {
  event: {}
}

export default EventCard